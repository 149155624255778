/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa');
// @font-face {
//     font-family: 'Comfortaa';
//     src: url('/assets/fonts/Comfortaa.woff') format('woff');
//   }

html {
    scroll-behavior: smooth;
  }

body {
    height: 100vh;
    background-color: #ffffff;
    // background-color:#f8f9fe;
}

.btn-menta {
    font-family: 'Comfortaa', sans-serif;
    text-transform: uppercase;
}